import React from 'react'
import './styles/Home.css'
import Numbers from '../components/Home/Numbers'
import Navbar from '../components/Navbar/Navbar'
import Section from '../components/Home/Section'
import Services from '../components/Home/Services'
import Objectif from '../components/Home/Objectif'
import Footer from '../components/Footer/Footer'
import Contact from '../components/Home/Contact'

const Home = () => {
  return (
    <div className='Home'>
        <Navbar/>
        <Section/>
        <Numbers/>
        <Objectif/>
        <Services/>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Home