import React, { useEffect, useRef, useState } from 'react';
import './FilterBar.css';

export default function FilterBar({ callback }) {
  const refSearchBar = useRef(null);
  const refCategorySelect = useRef(null);
  const refFileTypeSelect = useRef(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://mentorini.tn/api/subjects/');
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const category = [...new Set(data.map(subject => subject.category))];

  function handleChange() {
    callback([
      refSearchBar.current.value,
      refCategorySelect.current.value,
      refFileTypeSelect.current.value
    ]);
  }

  return (
    <div className="filters">
      <input
        type="text"
        className="searchBar"
        ref={refSearchBar}
        onChange={handleChange}
        placeholder="Saisissez un mot-clé..."
      />

      <select
        className="category"
        ref={refCategorySelect}
        onChange={handleChange}
        defaultValue=""
      >
        <option value="">-Catégories-</option>
        {category.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
      <select
        className="fileType"
        ref={refFileTypeSelect}
        onChange={handleChange}
        defaultValue=""
      >
        <option value="">-Type-</option>
        <option value="pdf">pdf</option>
        <option value="pptx">pptx</option>
        <option value="docx">docx</option>
      </select>
      <button
        className="reset"
        onClick={() => {
          refSearchBar.current.value = '';
          refCategorySelect.current.value = '';
          refFileTypeSelect.current.value = '';
          handleChange();
        }}
      >
        Réinitialiser
      </button>
    </div>
  );
}
