import React from 'react';
import './styles/Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const [ref1, inView1] = useInView({ threshold: 0 });
  const [ref2, inView2] = useInView({ threshold: 0 });
  const [ref3, inView3] = useInView({ threshold: 0 });

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const navigate = useNavigate()
  const goToRessources = () => {
    navigate('ressources')
  }
  return (
    <div className='Services'>
      <h2>Nos Services</h2>
      <div className='service-container'>
        <motion.div
          ref={ref1}
          className="service-card"
          variants={variants}
          initial='hidden'
          animate={inView1 ? 'visible' : 'hidden'}
        >
          <div className='service-card-header'>
            <FontAwesomeIcon icon={faBookOpen} className='icon-card' />
          </div>
          <div className='service-card-title'><h3>Ressources <span>gratuites</span></h3></div>
          <div className='content-card'>
            <p>
              Découvrez des documents essentiels et gratuits qui renforceront votre préparation
              aux examens et offriront ainsi une expérience de révision complète tout en offrant la possibilité
              de partager les documents entre les étudiants.
            </p>
          </div>
          <div className='card-btn'>
            <button onClick={goToRessources}>Explorer</button>
          </div>
        </motion.div>
        <motion.div
          ref={ref2}
          className="service-card"
          variants={variants}
          initial='hidden'
          animate={inView2 ? 'visible' : 'hidden'}
        >
          <div className='service-card-header'>
            <FontAwesomeIcon icon={faGraduationCap} className='icon-card' />
          </div>
          <div className='service-card-title'><h3>Pack <span>PFE</span></h3></div>
          <div className='content-card'>
            <p>
              Nous offrons un accompagnement spécialisé pour les étudiants dans
              la réalisation de leurs projets de fin d'études en offrant
              des formations ciblées ainsi qu'un suivi personnalisé assuré par des experts
              dans le domaine.
            </p>
          </div>
          <div className='card-btn'>
            <button id='encours'>En cours</button>
          </div>
        </motion.div>
        <motion.div
          ref={ref3}
          className="service-card"
          variants={variants}
          initial='hidden'
          animate={inView3 ? 'visible' : 'hidden'}
        >
            <div className='service-card-header'>
              <FontAwesomeIcon icon={faBookOpen} className='icon-card' />
            </div>
            <div className='service-card-title'><h3>Formations <span> Adaptées</span></h3></div>
            <div className='content-card'>
              <p>
                Nous proposons des formations adaptées aux spécificités du programme tunisien,
                dispensées par des experts chevronnés, offrant ainsi à nos participants
                les connaissances nécessaires pour exceller dans leurs domaines.
              </p>
            </div>
            <div className='card-btn'>
              <button id='encours'>En cours</button>
            </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Services;
