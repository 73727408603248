import React, {useEffect, useState} from 'react'
import './Navbar.css'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
          const isScrolled = window.scrollY > 80;
          setScrolled(isScrolled);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const navigate = useNavigate()
  const goToHome = () => {
    navigate('/')
  }
  const goToRessources = () => {
    navigate('/ressources')
  }
  const goToForm = () => {
    navigate('/ajouter')
  }
  const goToObjectif = () => {
    navigate('/');
    setTimeout(() => {
      const element = document.getElementById('Objectif');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0); // Timeout to ensure the page has fully rendered before scrolling
  };
  return (
    <div className="header">
      <div className='Navbar'>
        <p className="logo" onClick={goToHome} style={{cursor:'pointer'}}>MenTorini</p>
        <ul>
          <li onClick={goToHome}>Accueil</li>
          <li onClick={goToRessources}>Ressources</li>
          <li onClick={goToObjectif}>À Propos</li>
        </ul>
        <button onClick={goToForm}>Publier</button>
      </div>
      <div id="navBarWrapper" className={scrolled? "m-navBar-wrapper" : "m-navBar-wrapper transparent"} data-expanded="false">
        <div className="m-navBar">
            <div className="top">
                <a className="logo" onClick={goToHome}>MenTorini</a>
                <button onClick={()=> {
                  var e = document.getElementById('navBarWrapper');
                  e.setAttribute('data-expanded', e.getAttribute('data-expanded') == 'false');}
                  }>
                    <svg viewBox="0 0 10 10" width="100%" height="100%">
                        <path d="M2,2 L8,2 L8,5 L6,5 L2,5 L2,8 L8,8 L2,2 L8,2 L2,8" fill="none" stroke="black"/>
                    </svg>
                </button>
            </div>
            <div className="middle">
                <a onClick={goToHome} className="selected">Accueil</a>
                <a onClick={goToRessources}>Ressources</a>
                <a onClick={goToObjectif}>À Propos</a>
            </div>
            <a onClick={goToForm}>Publier</a>
            <span className="highlighter"></span>
        </div>
    </div>
  </div>
  )
}

export default Navbar