import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Form from '../components/AddForm/Form'
import Footer from '../components/Footer/Footer'

const AddForm = () => {
  return (
    <div>
      <Navbar/>
      <Form/>
      <Footer/>
    </div>
  )
}

export default AddForm
