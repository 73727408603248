import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you're using axios for HTTP requests
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import DocItem from '../components/DocList/DocItem';
import FilterBar from '../components/FilterBar/FilterBar'
import Spinner from '../components/Custom/Spinner'
import './styles/AllDocs.css'
import { useNavigate } from 'react-router-dom';

const AllDocs = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState(["","",""])


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to fetch data from your API endpoint

        const response = await axios.get('https://mentorini.tn/api/ressources/get');
        setData(response.data.data); // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []); // Empty dependency array means this effect runs once after the first render

  useEffect(() => {
    // Afficher le message "Aucune donnée disponible" après 5 secondes si aucune donnée n'a été récupérée
    const timeout = setTimeout(() => {
      if (!data && !isLoading) {
        setIsLoading(true);
      }
    }, 5000);

    return () => clearTimeout(timeout); // Nettoyer le timeout lors du démontage du composant
  }, [data, isLoading]);

  const navigate = useNavigate()
  const goToForm = () => {
    navigate('/ajouter')
  }
  return (
    <div style={{ minHeight:'100vh'}}>
      {data ? (
        <>
          
          <Navbar/>

          <div className="topHeader">
            <a>Acceuil &gt; <b>Ressources</b></a>
            <span className="sep"></span>
            <button onClick={goToForm}>Ajouter un document +</button>
          </div>

          <FilterBar callback={setFilters} />
          <div className='ressource-list'>
            {
              data.filter((item) =>
                (item.documentTitle.toLowerCase().includes(filters[0].toLowerCase()) && (filters[1] === "" || item.speciality === filters[1]) && (filters[2] === "" || item.docUrl.substring(item.docUrl.lastIndexOf('.') + 1) === filters[2]))
              ).map((item,index) => (
                <DocItem key={index} item={item}/>
              ))
            }
          </div>
          <Footer/>
        </>
      ) : (
        
        <>
          <Spinner/>
        </>
      )}
    </div>
  );
};

export default AllDocs;
