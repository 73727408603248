import React, { useState } from 'react'
import './styles/Contact.css'

const Contact = () => {
const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const myemail = "benslamaoussama1919@gmail.com"

  const handleSendEmail = () => {
    const mailtoLink = `https://mail.google.com/mail/?view=cm&to=${myemail}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.open(mailtoLink);
  };
  return (
    <div className='Contact'>
        <div className='contact-form'>
            <h2>Envoyez-nous un message</h2>
            <p>Pour tout support, collaboration ou partenariat,
              n'hésitez pas à nous envoyer un message en utilisant le formulaire ci-dessous.
            </p> <br />
            <input type="text" placeholder='Saisissez votre sujet'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}/>

            <textarea name="" id="" cols="15" rows="10" placeholder='Saisissez votre message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}></textarea>
            <button onClick={handleSendEmail}>Envoyer</button>
        </div>
    </div>
  )
}

export default Contact