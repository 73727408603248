import React from 'react'
import './Spinner.css'

const Spinner = () => {
  return (
    <div className='loading-container'>
        <div className='loading-spinner'></div>
        {/* <div className='loading-message'>Adding the event...</div> */}
    </div>
  )
}

export default Spinner