import React from 'react';
import './styles/Section.css';
import img from '../../res/section-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Section = () => {
  const navigate = useNavigate()
  const goToRessources = () => {
    navigate('/ressources')
  }
  const goToAddForm = () => {
    navigate('/ajouter')
  }
  return (
    <div className='Section'>
      {/* left section */}
      <div className='section-left'>
        <div>
            <h1>
              La qualité fait la <span>différence</span>
            </h1>
            <p>
              Une plateforme abordable et conviviale conçue pour fournir aux étudiants tunisiens 
              des ressources précieuses, 
              simplifier la communication et faciliter le partage d'informations.
            </p>
            <div className='btn'>
              <button className='explore_btn' onClick={goToRessources}>Explorer</button>
              <button className='rejoindre_btn' onClick={goToAddForm}>Publier</button>
            </div>
            <span className='add-icon' onClick={goToAddForm}>
              <FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: '60px' }} />
            </span>
        </div>
      </div>
      {/* right section */}
      <div className='section-right'>
        <img src={img} alt='student learning' />
      </div>
    </div>
  );
}

export default Section;
