import React from 'react'
import './Footer.css'
import img from '../../res/play_store.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {  faEnvelope, faLocation, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <div className='Footer'>
        <div>
            <div className='Footer-element'>
                <p style={{marginBottom:'13px'}}>Obtenez l’application MenTorini</p>
                
                    <img src={img} alt='play store logo' />
            </div>
            <div className='Footer-element'>
                <p>Suivez-nous</p>
                <span>
                    <a className='footer-icon' href='https://www.facebook.com/profile.php?id=100093571124350'><FontAwesomeIcon icon={faFacebook} /></a>
                    <FontAwesomeIcon icon={faLinkedin} className='footer-icon'/>
                    <FontAwesomeIcon icon={faInstagram} className='footer-icon'/>
                </span>
            </div>
            <div className='Footer-element'>
                <p>Adresse</p>
                <span>
                    <FontAwesomeIcon icon={faLocation} className='footer-icon'/>
                    Monastir
                </span>
            </div>
            <div className='Footer-element'>
                <p>Contact</p>
                <span>
                    <FontAwesomeIcon icon={faPhone} className='footer-icon' style={{fontSize:'12px'}}/>
                    +216 28281153
                </span> <br/>
                <span>
                    <FontAwesomeIcon icon={faEnvelope} className='footer-icon' style={{fontSize:'12px'}}/>
                    hello.mentorini@gmail.tn
                </span> <br/>
            </div>
            <div className='footer-copyright'>Copyright © {new Date().getFullYear()} Mentorini</div>
        </div>
        
    </div>
  )
}

export default Footer
