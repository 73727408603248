import React from 'react'
import './styles/Objectif.css'
import img from '../../res/goal-img.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const Objectif = () => {
  return (
    <div className='Objectif' id='Objectif'>
       <div className='objectif-description'>
            <div>
              <h3>Nos objectifs :</h3>
              <p>
                  <span> <FontAwesomeIcon icon={faCheck} className='objectif-icon'/>
                    Encourager le partage de ressources entre étudiants.
                  </span> <br/>
                  <span><FontAwesomeIcon icon={faCheck} className='objectif-icon'/>
                    Fournir des services supplémentaires pour enrichir l'expérience éducative
                  </span><br/>
                  <span><FontAwesomeIcon icon={faCheck} className='objectif-icon'/>
                    Faciliter l'apprentissage avec des cours en ligne adaptés aux programmes tunisiens
                  </span><br/>
              </p>
              {/* <button className='objectif-button'>voir plus</button> */}
            </div>
        </div>
       <div className='objectif-img'>
           <img src={img} alt='Shared goals Cartoon Illustrations'/>
       </div>
      
    </div>
  )
}

export default Objectif
