import React from 'react';
import './styles/DocItem.css';
import pdf from '../../res/pdfLogo.png';
import pptx from '../../res/pptxLogo.png';
import docx from '../../res/docLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faMarker } from '@fortawesome/free-solid-svg-icons';

const DocItem = ({ item }) => {
  const extension = item.docUrl.substring(item.docUrl.lastIndexOf('.') + 1);

  let logo;
  if (extension === 'pdf') {
    logo = <img className="doc-icon" src={pdf} alt='pdf logo' />;
  } else if (extension === 'pptx') {
    logo = <img className="doc-icon" src={pptx} alt='power point logo' />;
  } else if (extension === 'docx') {
    logo = <img className="doc-icon" src={docx} alt='document logo' />;
  }

  return (
    <div className='DocItem'>
      <h6>
        {logo}
        <span className="doc-title">
          {item.documentTitle}
        </span>
      </h6>
      <p>
        {item.speciality}
      </p>
      <button><a href={item.docUrl}>Ouvrir</a></button>
    </div>
  );
};

export default DocItem;
