import React, { useEffect, useRef, useState } from 'react';
import './styles/Numbers.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faLaptopCode, faUsers } from '@fortawesome/free-solid-svg-icons';

const Numbers = () => {
  const [countBook, setCountBook] = useState(0);
  const [countUsers, setCountUsers] = useState(0);
  const [countCours, setCountCours] = useState(0);

  const numbersRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.disconnect();
            startCounting();
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    observer.observe(numbersRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const startCounting = () => {
    const duration = 2000; // Set the duration for the counting animation in milliseconds
    const steps = 50; // Set the number of steps

    const incrementBook = 50 / steps;
    const incrementUsers = 60 / steps;
    const incrementCours = 2 / steps;

    let currentStep = 0;

    const intervalId = setInterval(() => {
      setCountBook((prevCount) => Math.min(prevCount + incrementBook, 50));
      setCountUsers((prevCount) => Math.min(prevCount + incrementUsers, 200));
      setCountCours((prevCount) => Math.min(prevCount + incrementCours, 80));

      currentStep += 1;

      if (currentStep === steps) {
        clearInterval(intervalId);
      }
    }, duration / steps);
  };

  return (
    <div className='Numbers' ref={numbersRef}>
      <ul>
        <li>
          <div>
            <FontAwesomeIcon icon={faBook} className='number-icon' /> <br />
            <span>{Math.round(countBook)}+</span>
            <p>Ressources</p>
          </div>
        </li>
        <li>
          <div>
            <FontAwesomeIcon icon={faUsers} className='number-icon' /> <br />
            <span>{Math.round(countUsers)}+</span>
            <p>Utilisateurs</p>
          </div>
        </li>
        <li>
          <div>
            <FontAwesomeIcon icon={faLaptopCode} className='number-icon' /> <br />
            <span>{Math.round(countCours)}+</span>
            <p>Cours</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Numbers;
