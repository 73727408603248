import React, { useEffect, useState } from 'react';
import './styles/Form.css';
import img from '../../res/form-img.png';
import CustomAlert from '../Custom/CustomAlert';
import Spinner from '../Custom/Spinner';
import axios from 'axios';

const Form = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [formData, setFormData] = useState({
        documentTitle: '',
        speciality: '',
        selectedSubject: '',
        selectedFile: null,
        accepted: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const cachedData = localStorage.getItem('cachedData');
                const cachedDataExpiration = localStorage.getItem('cachedDataExpiration');
                if (cachedData && cachedDataExpiration && new Date().getTime() < parseInt(cachedDataExpiration)) {
                    setData(JSON.parse(cachedData));
                } else {
                    const response = await axios.get('https://mentorini.tn/api/subjects/');
                    const jsonData = response.data;
                    setData(jsonData);
                    localStorage.setItem('cachedData', JSON.stringify(jsonData));
                    // Set expiration time
                    const expiration = new Date().getTime() + 3 * 24 * 60 * 60 * 1000; // 3 days
                    localStorage.setItem('cachedDataExpiration', expiration);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const category = [...new Set(data.map(subject => subject.category))];

    const closeAlert = () => {
        setShowAlert(false);
    };

    const handleChange = (e) => {
        const { name, type, files } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'file' ? files[0] : e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        try {
            setLoading(true);
            if (!formData.selectedFile || !formData.documentTitle || !formData.selectedSubject || !formData.speciality) {
                setAlertMessage('Veuillez remplir tous les champs requis');
                setShowAlert(true);
                return;
            }
            
            const formDataToSend = new FormData();
            formDataToSend.append('documentTitle', formData.documentTitle);
            formDataToSend.append('speciality', formData.speciality);
            formDataToSend.append('selectedSubject', formData.selectedSubject);
            formDataToSend.append('file', formData.selectedFile);
            formDataToSend.append('accepted', formData.accepted);

            // Send a POST request to your backend endpoint
            const response = await axios.post('https://mentorini.tn/api/ressources/upload', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setAlertMessage('Merci ! Le document a été ajouté avec succès. Avant sa publication, un administrateur va le vérifier');
            setFormData({
                documentTitle: '',
                speciality: '',
                selectedSubject: '',
                selectedFile: null,
                accepted: false,
            })
            setShowAlert(true);
        } catch (error) {
            setAlertMessage("Désolé, une erreur s'est produite");
            setFormData({
                documentTitle: '',
                speciality: '',
                selectedSubject: '',
                selectedFile: null,
                accepted: false,
            })
            setShowAlert(true);
        } finally {
            setLoading(false); // Set loading state to false after request completes
        }
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <div className='FormContainer'>
                    <div className='FormPicture'>
                        <img src={img} alt='A girl with books' />
                    </div>
                    <div className='Form'>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label>Le fichier (PDF ou PPTX) :</label>
                                <input type='file' name='selectedFile' onChange={handleChange} />
                                <label>Titre :</label>
                                <input type='text' name='documentTitle' onChange={handleChange} placeholder='cours UML'/>
                                <label>Choisir la spécialité :</label>
                                <select name='speciality' onChange={handleChange}>
                                    <option value="">--</option>
                                    {category.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                                <label>Choisir la matière :</label>
                                <select name='selectedSubject' onChange={handleChange}>
                                    <option value="">--</option>
                                    {data
                                        .filter(item => item.category === formData.speciality)
                                        .map((item, index) => (
                                            <option key={index} label={item.name} value={item.name}>{item.name}</option>
                                        ))}
                                </select>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6rem' }}>
                                    <input type='submit' value="Ajouter" id='button' />
                                </div>
                            </div>
                        </form>
                    </div>
                    {showAlert && <CustomAlert message={alertMessage} onClose={closeAlert} />}
                </div>
            )}
        </>
    );
};

export default Form;
